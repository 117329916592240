/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Cosas de Wordpress, instalas un plugin que hace exactamente lo que tú quieres y cuando empiezas a verlo funcionar te dan errores por todas partes del blog. Pues eso mismo me ha pasado con el plugin Webmentions que os comentaba en la última entrada, ha sido hacerme una mención a mí mismo y empezar a dar errores todo el blog de falta de memoria. Seguramente esto se deba una mala gestión de la memoria en el plugin, ya me avisaba Wordpress de que este plugin no estaba probado para esa versión, la 4.1. Además no conseguía aprobar los comentarios de las menciones, en fin, muy frustrante."), "\n", React.createElement(_components.p, null, "Espero que en el futuro esto se resuelva y pueda por fin utilizar las Webmentions."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
